@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"), url(/fonts/OpenSans-Regular.ttf) format("truetype");
}

* {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  background-color: #fff;
  font-size: 14px;
  line-height: 1.8em;
  font-family: OpenSans, Arial, Helvetica, sans-serif !important;
  overflow-y: scroll;
}
.dynamicimagemap {
  z-index: 10 !important;
}

